<template>
  <div :redraw="redraw">
    <div v-for="(item, k) in reportItems" :key="k">
      <h6>{{item.typeName}}</h6>
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th>#</th>
          <th>ФИО</th>
          <th>Класс</th>
          <th>ИНН</th>
          <th>Дата рождения</th>
          <th>Адрес</th>
          <th>ФИО родителя</th>
          <th>ИНН родителя</th>
          <th>Место работы</th>
          <th>Должность</th>
          <th>Адрес работы</th>
          <th>Номер телефона</th>
          <th v-if="typeConfig[k].stringAttribute1">{{typeConfig[k].stringAttribute1}}</th>
          <th v-if="typeConfig[k].stringAttribute2">{{typeConfig[k].stringAttribute2}}</th>
          <th v-if="typeConfig[k].stringAttribute3">{{typeConfig[k].stringAttribute3}}</th>
          <th v-if="typeConfig[k].numberAttribute1">{{typeConfig[k].numberAttribute1}}</th>
          <th v-if="typeConfig[k].numberAttribute2">{{typeConfig[k].numberAttribute2}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(student, index) in item.items" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ student.studentName }}</td>
          <td>{{ student.groupName }}</td>
          <td>{{ student.inn }}</td>
          <td>{{ student.birthDate }}</td>
          <td>{{ student.address }}</td>
          <td>{{ student.parentName }}</td>
          <td>{{ student.parentInn }}</td>
          <td>{{ student.parentWorkPlace }}</td>
          <td>{{ student.parentWorkPosition }}</td>
          <td>{{ student.parentWorkAddress }}</td>
          <td>{{ student.parentPhoneNumber }}</td>
          <td v-if="typeConfig[k].stringAttribute1">{{student.stringAttribute1}}</td>
          <td v-if="typeConfig[k].stringAttribute2">{{student.stringAttribute2}}</td>
          <td v-if="typeConfig[k].stringAttribute3">{{student.stringAttribute3}}</td>
          <td v-if="typeConfig[k].numberAttribute1">{{student.numberAttribute1}}</td>
          <td v-if="typeConfig[k].numberAttribute2">{{student.numberAttribute2}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>  
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
export default {
  name: "SchoolSocialPassportReportTable",
  components: {
  },
  data() {
    return {
      typeConfig:{},
      reportItems:[],
      redraw:0
    };
  },
  mounted() {
      
  },
  methods: {
    redrawTable(typeConfig, reportItems){
      this.typeConfig = typeConfig;
      this.reportItems = reportItems;
      
      this.redraw = Math.random();
    }
  }
};
</script>
