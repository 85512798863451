<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Социальный паспорт школы 
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="filter.educationYear"
                        :options="educationYears"
                        @change="reloadGroups"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        v-model="filter.level"
                        :options="levels"
                        @change="reloadGroups"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Класс
                    </template>
                    <b-form-select
                        v-model="filter.studentGroupId"
                        :options="groups"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary">{{
                  $t("common.filter")
                }}
              </b-button>

<!--              <b-button type="button" @click="exportData" variant="primary" style="margin-left: 10px">Экспорт</b-button>-->
            </b-form>
            <SchoolSocialPassportReportTable ref="schoolSocialPassportReportTable"></SchoolSocialPassportReportTable>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
import SchoolSocialPassportReportTable from "./partials/SchoolSocialPassportReportTable.vue";

export default {
  name: "SchoolSocialPassportReport",
  components: {
    KTPortlet,
    SchoolSocialPassportReportTable
  },
  data() {
    return {
      groups:[],
      levels:DictionariesService.levelOptions(),
      educationYears: DictionariesService.educationYearOptions(),
      filter:{
        level:0,
        studentGroupId:0,
        educationYear: DictionariesService.currentYear()
      },
      typeConfig:{},
      data:{},
      reportItems:[]
    };
  },
  mounted() {
      
  },
  methods: {
    reloadGroups(){
      ApiService.querySecured("dictionaries/groupsForFilter",
          {params: {educationYear: this.filter.educationYear, level: this.filter.level}})
          .then(({data}) => {
            data.dictionary.unshift({ value: 0, text: '' });
            this.groups = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });
    },
    reloadData(){
      let $this = this;
      ApiService.postSecured("schoolReports/socialPassport", this.filter).then(({data}) => {
        $this.data = data;
        $this.reportItems = data.reportItems;
        
        for (let i in data.reportItems){
          let group = data.reportItems[i];
          $this.typeConfig[i] = JSON.parse(group.config);
        }

        this.$refs['schoolSocialPassportReportTable'].redrawTable($this.typeConfig, $this.reportItems);
      }).catch(({response}) => {
        console.log(response);
      });
    },
    exportData(){
      let filters = this.filter;
      filters['export'] = true;
      ApiService.downloadSecured("schoolReports/eduProgressReport", filters)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Отчет.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    }
  }
};
</script>
